import React, { Component } from "react"
import { graphql, Link, navigate } from "gatsby"
import Image from "gatsby-image"
import {
  genPrefix,
  getTranslations,
  changeToLocalLinks,
} from "../components/helpers/helpers"

import Layout from "../components/layout"
import SEO from "../components/seo"
import i18Data from "../../content/intl/labels.json"
import "../styles/home.scss"
import { getCookie, setCookie } from "../components/helpers/domHelpers"

class Homepage extends Component {
  componentDidMount() {
    if (!getCookie("ba_lang")) {
      if (
        window.navigator.language &&
        window.navigator.language.indexOf("es") === 0
      ) {
        setCookie("ba_lang", "ok", 1)
        navigate("/es")
        return
      }
    }

    this.windowRef = window
    this.documentRef = document
    this.linksUnsubs = changeToLocalLinks(this.isHtml, navigate, [
      i18Data.common.url,
      i18Data.common.altUrl,
    ])
  }

  componentWillUnmount() {
    if (this.isHtml) {
      this.isHtml.removeEventListener("click", this.linksUnsubs)
    }
  }

  render() {
    const {
      data: { arrunadaIlustration, mobileImage, pageData, bioPage },
      pageContext: { lang },
    } = this.props
    const page = pageData.edges.filter(
      ({ node }) => node.frontmatter.lang === lang
    )[0]?.node
    const translations = getTranslations(pageData.edges, "home")
    const bioPageData =
      bioPage && bioPage.edges && bioPage.edges[0] && bioPage.edges[0].node
    const i18 = i18Data[lang]

    const sources = [
      mobileImage.childImageSharp.fluid,
      {
        ...arrunadaIlustration.childImageSharp.fluid,
        media: `(min-width: 640px)`,
      },
    ]

    return (
      <Layout
        mainClassName="homepage"
        noActiveMenu={true}
        pageContext={this.props.pageContext}
        translations={translations}
      >
        <SEO
          title={page.frontmatter.title}
          description={page.frontmatter.summary}
          lang={lang ? lang : "en"}
        />

        <h1 className="hidden">{i18.siteMetadata.defaultTitle}</h1>

        <div className="home-container">
          <div
            className="column-left"
            role="presentation"
            aria-describedby="intro"
          >
            <Image fluid={sources} fadeIn={true} alt={"Benito Arruñada"} />
          </div>
          <div className="column-right">
            <h2
              id="intro"
              dangerouslySetInnerHTML={{ __html: page.frontmatter.summary }}
            ></h2>
            {bioPageData && (
              <Link
                to={genPrefix(lang, true) + bioPageData.frontmatter.slug}
                className="big-link no-text"
              >
                Bio
              </Link>
            )}
            <div
              className="is-html"
              ref={t => {
                this.isHtml = t
              }}
              dangerouslySetInnerHTML={{ __html: page.html }}
            ></div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Homepage

export const query = graphql`
  query HomeQuery($lang: String!) {
    arrunadaIlustration: file(
      relativePath: { eq: "arrunada-illustration.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileImage: file(relativePath: { eq: "arrunada-illustration.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 290, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pageData: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "home" } } }
    ) {
      edges {
        node {
          frontmatter {
            lang
            template
            slug
            summary
            title
          }
          id
          html
        }
      }
    }
    bioPage: allMarkdownRemark(
      filter: { frontmatter: { lang: { eq: $lang }, template: { eq: "bio" } } }
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            lang
            template
            slug
            summary
            title
          }
          id
          html
        }
      }
    }
  }
`
